import React, {Fragment} from "react";
import {ContactHeader} from "../components/header/ContactHeader";
import {PlainHeader} from "../components/header/PlainHeader";
import {Hero} from "../components/splash/hero/Hero";
import {Feature} from "../components/splash/feature/Feature";
import {Footer} from "../components/splash/footer/Footer";
import {Link} from "react-router-dom";
import {Button} from "@contentmunch/muncher-ui";
import {PageProps} from "../data/PageProps";

export const Home: React.FC<PageProps> = ({contact}) => {
    return (
        <Fragment>
            {/*<BannerSection/>*/}
            <ContactHeader contact={contact}/>
            <PlainHeader contact={contact}/>
            <main className="splash">
                <Hero/>
                <Feature>
                    <div className="feature-left">
                        <h2>short term</h2>
                        <ul>
                            <li>14 day minimum, no binding contracts</li>
                            <li>spacious 1 & 2 bedrooms</li>
                            <li>Fully furnished & equipped like your home</li>
                            <li>Full kitchen with kitchenwares provided</li>
                            <li>Washer & Dryer included</li>
                        </ul>
                        <h2 className="feature-btn">
                            <Button variant="secondary">
                                <Link to="/short-term">Learn More »</Link>
                            </Button>
                        </h2>

                    </div>
                    <div className="feature-middle">
                        <h2><i>or</i></h2>
                    </div>
                    <div className="feature-right">
                        <h2>long term?</h2>
                        <ul>
                            <li>one year lease</li>
                            <li>1 & 2 bedroom apartment flats & lofts, and 2 & 3 bedroom town homes</li>
                            <li>All utilities included in most apartments</li>
                            <li>Free Internet & Cable TV in most apartments</li>
                        </ul>
                        <h2 className="feature-btn">
                            <Button variant="secondary">
                                <Link to="/long-term">Learn More »</Link>
                            </Button>
                        </h2>
                    </div>
                </Feature>
            </main>
            <Footer contact={contact}/>
        </Fragment>
    );
};
